<template>
  <div class="container">
    <div class="fixed"></div>
    <!-- 顶部导航栏 -->
    <div class="header">
      <div class="menus">
        <div class="menu">关于良才</div>
        <div class="menu">联系良才</div>
        <div class="menu">辅导需求</div>
        <div class="menu">支持良才</div>
      </div>

      <div class="burger" @click="openMenu">
        <div
          class="searchIcon"
          @click.stop="startSearch"
          style="display: none"
          :class="searchHidden ? 'MenuRise' : 'MenuDropDownClass'"
        >
          <img src="../assets/images/search.png" style="width: 20px" alt="" />
        </div>
        <div class="burger-line1" :class="open ? 'open-line1' : ''"></div>
        <div class="burger-line2" :class="open ? 'open-line2' : ''"></div>
        <div class="burger-line3" :class="open ? 'open-line3' : ''"></div>
      </div>
      <div class="switch">华语简体</div>
      <!-- 适配1120分辨率时 -->
      <div class="showMenuWhenOpen" v-show="open">
        <div class="logo">
          <img
            src="../assets/images/logo.png"
            alt=""
            @click="inToWelcomePart"
          />
        </div>
        <div class="open-menus">
          <div class="menu">关于良才</div>
          <div class="menu">联系良才</div>
          <div class="menu">辅导需求</div>
          <div class="menu">支持良才</div>
          <div
            class="menu"
            @click="inToMarriageFamily"
            :class="key == 1 ? 'active-color' : ''"
          >
            婚姻家庭
          </div>
          <div
            class="menu"
            @click="inToYoungerEducation"
            :class="key == 3 ? 'active-color' : ''"
          >
            亲子教育
          </div>
          <div
            class="menu"
            @click="inToMarriageInstruction"
            :class="key == 2 ? 'active-color' : ''"
          >
            婚恋辅导
          </div>
          <div class="menu">婚恋资源</div>
          <div
            class="menu"
            @click="inToYoungerExam"
            :class="key == 5 ? 'active-color' : ''"
          >
            千里马测评
          </div>
          <div class="menu">良才资源</div>
          <div class="menu">良才报名</div>
          <div class="open-switch">华语简体</div>
        </div>
      </div>
    </div>
    <div class="nav" v-show="!open">
      <div class="logo" @click="inToWelcomePart">
        <img src="../assets/images/logo.png" alt="" />
      </div>
      <!-- 标题布局 -->
      <div
        class="secondMenus"
        ref="menu"
        :class="searchHidden ? 'MenuRise' : 'MenuDropDownClass'"
      >
        <div
          class="secondMenu"
          @click="inToMarriageFamily"
          :class="key == 1 ? 'active-color' : ''"
        >
          婚姻家庭
        </div>
        <div
          class="secondMenu"
          :class="key == 2 ? 'active-color' : ''"
          @click="inToMarriageInstruction"
        >
          婚恋辅导
        </div>
        <div
          class="secondMenu"
          @click="inToYoungerEducation"
          :class="key == 3 ? 'active-color' : ''"
        >
          亲子教育
        </div>
        <div class="secondMenu" :class="key == 4 ? 'active-color' : ''">
          婚恋资源
        </div>
        <div
          class="secondMenu"
          :class="key == 5 ? 'active-color' : ''"
          @click="inToYoungerExam"
        >
          千里马测评
        </div>
        <div class="secondMenu" :class="key == 6 ? 'active-color' : ''">
          良才资源
        </div>
        <div class="secondMenu" :class="key == 7 ? 'active-color' : ''">
          良才报名
        </div>
        <div class="searchIcon" @click="startSearch">
          <img src="../assets/images/search.png" alt="" />
        </div>
      </div>
      <!-- 搜索功能布局 -->
      <div
        v-show="initHidden == false"
        class="search"
        :class="searchHidden ? 'SearchDropDownClass' : 'SearchRiseClass'"
      >
        <input type="text" placeholder="Search ..." class="searchInput" />
        <img src="../assets/images/cancel.png" alt="" @click="startSearch" />
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      key: 0,
      open: false, // 点击三横杠 打开隐藏的菜单列表
      initHidden: true, // 初始化时会加载一遍动画 用initHidden 来进行隐藏
      searchHidden: true, //开关用于动态的加载 搜索框和二级标题的切换
    };
  },
  created() {},
  methods: {
    startSearch(event) {
      // 已经可以显示动画了 initHidden 失去了作用
      this.initHidden = false;
      if (this.searchHidden == true && this.open == false) {
        this.searchHidden = false;
      } else if (this.searchHidden == false && this.open == false) {
        this.searchHidden = true;
      }
    },
    openMenu() {
      // 开关
      this.open = !this.open;
      this.initHidden = true;
    },
    // 跳转到婚姻家庭页面
    inToMarriageFamily() {
      this.key = 1;
      this.$router.push({
        path: "/marriageFamily",
      });
      if (this.open == true) {
        this.open = false;
      }
    },
    // 跳转到首页
    inToWelcomePart() {
      this.key = 0;
      this.$router.push({
        path: "/welcome",
      });
    },
    // 进入良才教育页面
    inToYoungerEducation() {
      this.key = 3;
      this.$router.push({
        path: "/youngerEducation",
      });
      if (this.open == true) {
        this.open = false;
      }
    },
    // 进入婚姻指导
    inToMarriageInstruction() {
      this.key = 2;
      this.$router.push({
        path: "/marriageInstruction",
      });
      if (this.open == true) {
        this.open = false;
      }
    },
    inToYoungerExam() {
      if (this.$isMobile()) {
        this.$message("请进入用电脑访问喔");
      } else {
        this.key = 5;
        this.$router.push({
          path: "/youngerTest",
        });
        if (this.open == true) {
          this.open = false;
        }
      }
    },
  },
};
</script>

<style  lang='less' scoped>
// 菜单栏选中项的颜色
.active-color {
  color: #52be80 !important;
}
// Formatting Model（布局方式、位置） > Box Model（尺寸）Typographic（文本相关） > Visual（视觉效果） 的顺序书写
.container {
  width: 100%;
  overflow-x: hidden;
  .fixed {
    width: 100%;
    height: 217px;
  }
  // 一级导航栏
  .header {
    position: fixed;
    top: 0;
    z-index: 20;
    display: flex;
    width: 100%;
    align-items: center;
    height: 67px;
    background-color: #5499c7;
    .switch {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 35px;
      margin-right: 200px;
      background-color: #52be80;
      border-radius: 10px;
      font-weight: 550;
      color: #fff;
    }
    .menus {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      width: 100%;
      height: 100%;
      font-weight: 550;
      .menu {
        display: inline-block;
        margin: 0 20px;
        font-size: 14px;
        color: #fff;
        list-style: none;
        text-decoration: none;
      }
      .menu:last-child {
        margin-right: 30px;
      }
    }
  }
  //二级导航栏
  .nav {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 67px;
    z-index: 20;
    height: 150px;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
    background-color: #fff;
    .logo {
      width: 150px;
      height: 150px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    // 二级菜单
    .secondMenus {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 200px;
      font-weight: 550;
      font-size: 15px;
      color: #3498db;
      transition: 0.4s;
      .secondMenu {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 44px;
        margin-left: 20px;
        cursor: pointer;
      }

      .secondMenu:hover {
        border-radius: 10px;
        background-color: #3498db;
        color: #fff;
      }
      .searchIcon {
        margin-left: 20px;
        cursor: pointer;
      }
      .searchIcon img {
        width: 20px;
        height: 20px;
      }
    }
    .searchIcon {
      margin-left: 20px;
      cursor: pointer;
    }
    .searchIcon img {
      width: 20px;
      height: 20px;
    }
    // 搜索框
    .search {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 640px;
      height: 40px;
      margin-left: 475px;
      font-size: 24px;
      opacity: 0;
      margin-top: -140px;
      transition: 0.4s;
      color: #3498db;
      input::-webkit-input-placeholder {
        /* placeholder颜色 */
        color: #3498db;
        /* placeholder字体大小 */
        font-size: 20px;
      }
      .searchInput {
        outline: none;
        border: none;
        height: 40px;
        width: 100%;
        color: #3498db;
        font-size: 20px;
      }
      img {
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
    }
  }
}

// 菜单上升样式 动态绑定
.MenuRiseClass {
  animation: MenuRise 0.5s ease-in-out forwards;
}
//  菜单下坠样式 动态绑定
.MenuDropDownClass {
  animation: MenuDropDown 0.5s ease-in-out forwards;
}

//  搜索下坠样式 动态绑定
.SearchDropDownClass {
  animation: SearchDropDown 0.5s ease-in-out forwards;
}
//  搜索上升样式 动态绑定
.SearchRiseClass {
  animation: SearchRise 0.5s ease-in-out forwards;
}

// 按钮上升动画
@keyframes SearchRise {
  from {
    transform: translateY(0);
    opacity: 0;
  }
  to {
    /* 恢复到原先的地方 */
    transform: translateY(50px);
    opacity: 1;
  }
}
// 按钮下坠动画
@keyframes SearchDropDown {
  from {
    transform: translateY(50px);
    opacity: 1;
  }
  to {
    /* 恢复到原先的地方 */
    transform: translateY(0);
    opacity: 0;
  }
}
// 菜单下坠效果
@keyframes MenuDropDown {
  from {
    /* 恢复到原先的地方 */

    transform: translateY(0);
    opacity: 1;
  }
  to {
    /* 通过透明度来让其隐藏*/
    transform: translateY(50px);
    opacity: 0;
  }
}
// 菜单上升效果
@keyframes MenuRise {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* 定义菜单项 下滑的效果 */
@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    //从标题栏开始下拉
    height: 800px;
    padding-top: 80px;
    opacity: 1;
  }
}
// 定义菜单栏的内容显示出来
@keyframes showMenu {
  from {
    opacity: 0;
    transform: translateY(-1vh);
  }
  to {
    opacity: 1;
    /* 到他原有的位置 */
    transform: translateY(0);
  }
}
@media screen and (max-width: 1140px) {
  /**最大媒体宽度**/
  .header {
    position: relative;
  }
  .header .burger {
    display: block;
    width: 80px;
    height: 40px;
    cursor: pointer;
    .burger-line1,
    .burger-line2,
    .burger-line3 {
      width: 20px;
      height: 2px;
      background-color: #fff;
    }

    .searchIcon {
      display: block !important;
      position: absolute;
      right: 50px;
      top: 140px;
      margin-left: 20px;
      cursor: pointer;
    }
    .searchIcon img {
      width: 20px;
      height: 20px;
    }

    .burger-line1 {
      position: absolute;
      right: 20px;
      top: 26px;
    }
    .burger-line2 {
      position: absolute;
      right: 20px;
      height: 1px;
      top: 33px;
    }
    .burger-line3 {
      position: absolute;
      right: 20px;
      top: 38px;
    }
  }
  /* burger-line1,2,3 的动画效果 就是为了把三条横线 变成X */
  .open-line1 {
    /*  */
    transform: rotate(45deg) translate(3px, 5px);
  }

  .open-line2 {
    /*  */
    transform: translateX(5px);
    opacity: 0;
  }

  .open-line3 {
    /*  */
    transform: rotate(-45deg) translate(3px, -5px);
  }
  .showMenuWhenOpen {
    display: flex;
    position: absolute;
    left: 0;
    top: 67px;
    width: 100vw;
    height: 600px;
    background-color: white;
    justify-items: flex-start;
    padding: 0 40px;
    opacity: 0;
    z-index: 500;
    /* 停留在最后一帧 */
    animation: slideDown 0.6s ease-out forwards;
    .logo {
      width: 250px;
      height: 150px;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .open-menus {
      display: block !important;
      width: 100px;
      height: 600px;
      margin: 0 20%;
      .menu {
        width: 140px;
        height: 35px;
        margin: 4px 0;
        font-weight: 550;
        color: #5499c7;
        animation: showMenu 0.5s linear forwards 0.4s;
        font-size: 18px;
        line-height: 35px;
        border-radius: 10px;
        opacity: 0;
        transition: all 0.5s ease forwards 0.4s;
        cursor: pointer;
      }
      .menu:hover {
        background-color: #fafafa;
      }
      .open-switch {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 35px;
        margin-right: 200px;
        animation: showMenu 0.5s linear forwards 0.4s;
        cursor: pointer;
        background-color: #52be80;
        border-radius: 10px;
        font-weight: 550;
        color: #fff;
      }
    }
  }
  // 当小于900px时候 隐藏二级菜单栏
  .header .menus,
  .secondMenus,
  .header .switch {
    display: none !important;
  }

  .fixed {
    display: none;
  }
  .container .nav,
  .container .header {
    position: unset;
  }
  // 用户订阅模块的响应式

  .searchInput {
    margin-left: -95vw;
    width: 20vw !important;
  }
  .nav {
    justify-content: flex-start !important;
  }
}
</style>

