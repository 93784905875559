import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    redirect: '/welcome',
    children: [
      {
        path: '/welcome', component: () => import('../views/welcome.vue')
      },
      // 热点话题
      {
        path: '/marriageFamily', component: () => import('../views/marriageFamily.vue')
      },
      // 亲子教育
      //youngerEducation 
      {
        path: '/youngerEducation', component: () => import('../views/youngerEducation.vue')
      },
      // 页面详情
      {
        name: "detail",
        path: "/detail", component: () => import("../views/topicDetail.vue")
      },
      // 婚恋指导
      {
        path: '/marriageInstruction', component: () => import("../views/marriageInstruction.vue")
      }

    ],

  },
  // 良才测试
  {
    path: '/youngerTest', component: () => import("../views/youngerTest.vue")
  },

]

const router = new VueRouter({
  routes
})

export default router
